
import React from 'react';

const CustomButton = ({title, callback, disabled=false, negative=false, background=false, style=false}) => {
  let mainColorScheme = negative ? 'border-green-500 text-green-500 bg-white ' : 'bg-green-500 text-white'
  if (background){
    mainColorScheme = background + " text-white"
  }
  return  <div 
  // + {negative ? 'text-orange-500 bg-white' : 'bg-orange-500 text-white'}
    className={style ? style : 'flex align-middle justify-center border-2 rounded-xl p-4 my-2  cursor-grab ' + mainColorScheme}
    onClick={disabled ? null : callback}
    style={ disabled ? {filter: "grayscale(100%)"} : null }>
        <div>{title}</div>
    </div>
  ;
};

export default CustomButton;
