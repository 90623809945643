"use client";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import logIn from "../lib/login";
import { useUserContext } from "../context/userContext";

const Login = ({toggleSignup}) => {
    const {setLoginState, setToken} = useUserContext()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    // const navigate = useNavigate();

    const onButtonClick = async () => {
        let loginReq = await logIn({email, password})
        if (loginReq?.key) {
            setLoginState(true)
            setToken(loginReq.key)
        }
    }

    return <div className={"mainContainer dark:text-white"}>
        {/* <div className={"titleContainer"}>
            <div>Login</div>
        </div> */}
        <br />
        <form>
            <div className={"inputContainer"}>
                <input
                    autoComplete="username"
                    value={email}
                    placeholder="Enter email"
                    onChange={ev => setEmail(ev.target.value)}
                    className={"inputBox text-black px-2 py-1"} />
                <label className="errorLabel">{emailError}</label>
            </div>
            <br />
            <div className={"inputContainer text-black"}>
                <input
                    autoComplete="current-password"
                    type="password"
                    value={password}
                    placeholder="Enter password"
                    onChange={ev => setPassword(ev.target.value)}
                    className={"inputBox px-2 py-1"} />
                <label className="errorLabel">{passwordError}</label>
            </div>
            <br />
            <div className={"inputContainer"}>
                <input
                    className={"inputButton text-black dark:text-white border rounded p-2 cursor-pointer"}
                    type="button"
                    onClick={onButtonClick}
                    value={"Log in"} />
            </div>
        </form>
        <div onClick={toggleSignup} className="mt-5 border rounded dark:border-white  p-2 text-justify text-black">
            <p className="text-black dark:text-white cursor-pointer">Or create an account here.</p>
        </div>
    </div>
}

export default Login