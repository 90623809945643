import React from 'react';

const StepperStep = ({ title, icon, active }) => {
    return (
      <div className={`flex items-center ${active ? '' : 'text-zinc-950'}`}>
        <div
          className={`${
            active ? 'bg-green-500 text-white' : 'bg-gray-300 text-zinc-950'
          } w-8 h-8 rounded-full flex items-center justify-center mr-4`}
        >
          {icon ? icon : null}
        </div>
        <div>
          <h3 className={`text-lg font-bold ${active ? '' : 'text-zinc-950'}`}>
            {title}
          </h3>
        </div>
      </div>
    );
  };
  
export default StepperStep;
