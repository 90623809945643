"use client";
import { useContext } from "react";
import LoginPage from "../login/page"
import Mainpage from "../mainpage/page"
import { useUserContext } from "../context/userContext"
import { BrowserRouter } from "react-router-dom";


const Landingpage = (pathname)=>{
    const {loginState} = useUserContext()
    return <div>
        {loginState ? <Mainpage {...pathname} />: <LoginPage/>}
    </div> 
}

export default Landingpage