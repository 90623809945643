"use client";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import logIn from "../lib/login";
import { useUserContext } from "../context/userContext";
const DisplayPersona = ({persona, setPersona}) => {
    const [jsonString, setJsonString] = useState(JSON.stringify(persona, null, 2))
    const checkIfObjOrList = (item)=>{
        return (Object.prototype.toString.call(item) === '[object Array]' || Object.prototype.toString.call(item) === '[object Object]')
    }
    
    return <pre className="text-wrap text-xs"><textarea value={persona} rows="25" cols="70" onChange={(e)=>{
        setPersona(e.target.value)
    }}/></pre>
}

export default DisplayPersona