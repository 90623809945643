import React, { createContext, useContext, useState } from 'react';

const StepContext = createContext();

export const StepProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [additionalData, setAdditionalData] = useState({});
  
  return (
    <StepContext.Provider value={{ activeStep, setActiveStep, additionalData, setAdditionalData }}>
      {children}
    </StepContext.Provider>
  );
};

export const useStep = () => {
  return useContext(StepContext);
};