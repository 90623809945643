import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { useUserContext } from './userContext';
import requestApi from '../lib/request';
import { json } from 'react-router-dom';
const user = {
    loggedIn : false,
    token : false,
    data : {name:'DEFAULT NAME'}
}
const GenericContext = React.createContext('');
const useChatContext = ()=> {
    return useContext(GenericContext);
}
const ChatContext = ({ children }) => {
    const { token, logout } = useUserContext()
    const [thread, setThread] = useState('')
    const [assistant, setAssistant] = useState('')
    const [assistantPersona, setAssistantPersona] = useState('asst_CRrKWodpIimaya8AHIQZu8s6')
    const [response, setResponse] = useState('')
    const [audioScript, setAudioScript] = useState('')
    const [audioSnippet, setAudioSnippet] = useState('')

    const chatInit = ()=>{
        const chat = JSON.parse(localStorage.getItem('chat'))
        if (!chat){
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"quarksChatInit"}})
        .then(res=>{
            setAssistant(res.data.assistant)
            setThread(res.threadId)
        })
        }
    }
    const addMessage = (message)=>{
        const body = {message, threadId:thread, assistantId:assistantPersona}
        console.log('this should be the body ', body)
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"addMessage"}, body})
        .then(res=>{
            setResponse(res.data)
            setThread(res.threadId)
        })
    }
    const initPersona = async (persona)=>{
        const body = {persona}
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"initPersona"}, body})
        .then(res=>{
            console.log("RESPONSE: ", res)
            setAssistantPersona(res.data)
            return res.data
        })
        return await resp
    }
    const audioInit = async ()=>{
        const chat = JSON.parse(localStorage.getItem('audio'))
        if (!chat){
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"quarksAudioInit"}})
        .then((res)=>{
            try{
                setAudioScript(JSON.parse(res.data))
            }catch{
                console.warn('parsing error')
            }
        })
        return await resp
        }
    }
    const createAudioSnippet = async ({headline, content}) => {
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"quarksAudioSnippet", headline, content}})
        .then((res)=>{
            try{
                setAudioSnippet(res)
                return res.data
            }catch{
                console.warn('parsing error')
            }
        })
        return await resp
    }
    const createAudioScript = async () => {
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"quarksSuggestScript"}})
        .then((res)=>{
            try{
                setAudioSnippet(res)
                return res.data
            }catch{
                console.warn('parsing error')
            }
        })
        return await resp
    }
    const fetchNewArticles = async () => {
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"quarksFetchNewArticles"}})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
            }
        })
        return await resp
    }
    const createRadioAd = async (data) =>{
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"createRadioAd"}, body:data})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }
    const getTrendsAndCampaigns = async () =>{
        // TODOJAN SERVER RESP IS CANNED CHANGE AFTER DEV/DEBUGGING
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"danexisDev"}})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }
    const getNewRadioAd = async (data, id) =>{
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"createArticle"}, body:{data, id}})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }
    const searchArticle = async (searchTerm) =>{
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"searchArticle", searchTerm}})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }
    const makeArticleAvailable = async (articleId) =>{
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"makeArticleAvailable", articleId}})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }
    const createAssistant = async (articleId, assistantType="dialogue", details={}) =>{
        let requestObj = {token, url:'request/', params:{res:'debug', action:"createAssistant", articleId, assistantType}, body:details}
        for (const[key, val] of Object.entries(details)){
            requestObj[key] = val
        }
        const resp = requestApi(requestObj)
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }

    const deleteFile = async({documentAiId}) => {
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"deleteArticleFile", documentAiId}})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }

    const deleteAssistant = async ({assistantAiId, searchTerm}) => {

        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"deleteAssistant", assistantAiId, searchTerm}})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }
    const requestDialogue = async (assistantId, amountOfQuestions = 5) => {
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"startDialogue", assistantId, amountOfQuestions}})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }
    const requestAnswer = async ({question, threadId, assistantAiId}) =>{
        console.log("THIS IS SO WEIRD",{question, threadId, assistantAiId})
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"requestAnswer"}, body:{question, threadId, assistantAiId}})
        .then((res)=>{
            try{
                console.log(res.data)
                console.log("THIS IS THE RAW RESPONSE AFTER REQUESTING AN ANSWER".res)
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }
    const createQuestionAudio = async ({answer}) =>{
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:"createQuestionAudio"}, body:{answer}})
        .then((res)=>{
            try{
                return res.data
            }catch{
                console.warn('parsing error')
                return {'error': 'parsing error'}
            }
        })
        return await resp
    }
    return (
        <GenericContext.Provider value={{
            thread, 
            setThread,
            assistant,
            setAssistant,
            assistantPersona, 
            setAssistantPersona,
            chatInit,
            addMessage,
            initPersona,
            response,
            audioInit,
            audioScript,
            setAudioScript,
            createAudioSnippet,
            audioSnippet,
            createAudioScript,
            createRadioAd,
            getTrendsAndCampaigns,
            getNewRadioAd,
            fetchNewArticles,
            searchArticle,
            makeArticleAvailable,
            createAssistant,
            requestDialogue,
            requestAnswer,
            createQuestionAudio,
            deleteAssistant,
            deleteFile
        }}>
            {children}
        </GenericContext.Provider>
    );
};
export {ChatContext, useChatContext};