import { useState, useEffect} from "react"
import { useChatContext } from "../context/chatContext"
import Spinner from "../ui/spinner"
import personaJson from "../data/persona.json"
import DisplayPersona from "../ui/displayPersona"
import FullscreenLoading from "../ui/FullscreenLoading"
const Persona = ({})=>{
    const {thread, addMessage, response, initPersona} = useChatContext()

    const [waiting, setWaiting] = useState(false)
    const [waitingFullscreen, setWaitingFullscreen] = useState(false)
    const [results, setResults] = useState(false)
    const [chatInput, setChatInput] = useState('')
    const [persona, setPersona] = useState(JSON.stringify(personaJson.persona, null, 2))
    const [chatHistory, setChatHistory] = useState([
        {role:'ai', content:'Stellen sie mir fragen.'},
    ])

    useEffect(() => {
        scrollToBottom()
    }, [chatHistory]);
    useEffect(() => {
        const aiResponse = {role:'ai', content:response}
        scrollToBottom()
        if (!response) {
            setWaiting(false)
            return
        }
        setChatHistory([...chatHistory, aiResponse])
        setChatInput('')
        setWaiting(false)
        scrollToBottom()
    }, [response]);
    
    const submitMessage = (e, msg)=>{
        e.preventDefault()
        // if (waiting) return
        setWaiting(true)
        const userInput = {role:'user', content:msg}
        setChatHistory([...chatHistory, userInput])
        setChatInput('')
        addMessage(msg)
        scrollToBottom()
    }
    
    const messages = chatHistory.map((message, index)=>{
        const userMsgStyle = 'text-right dark:text-white text-l p-4 mt-2 border dark:border-white rounded-lg place-self-end'
        const partnerMsgStyle = 'text-left text-black text-l p-4 mt-2 bg-white border border-black rounded-lg place-self-start'
        return <div className={`${message.role === 'user' ? userMsgStyle : partnerMsgStyle}`} key={`${message.content}-${index}`}>{message.content}</div>
    })
    const scrollToBottom = () => {
        document.querySelector('.chatHistory')?.scrollTo({
            top: document.querySelector('.chatHistory').scrollHeight,
            behavior: 'smooth',
        });
    }
    return <div className="currentContent">
        <div className="m-5 grid grid-cols-2">
            <div className={"inputContainer text-black col-span-1"}>
                {/* <p className="mb-2 dark:text-white">Current assistant: {assistant.name}</p> */}
                <p className="mb-2 dark:text-white">Current thread: {thread}</p>
                <div className="chatHistory border border-white rounded-lg p-5 mb-2 h-96 overflow-scroll flex flex-col">
                    {messages}
                </div>
                <form onSubmit={(e)=> submitMessage(e, chatInput)} className="flex">
                    <input
                        type="text"
                        value={chatInput}
                        placeholder="Enter Message"
                        onChange={ev => setChatInput(ev.target.value)}
                        className={"px-2 py-1"} 
                        />
                    <button disabled={waiting} className="dark:text-white p-2 border dark:border-white rounded-lg mx-2" type="submit">Send</button>
                {waiting ?
                <Spinner/>:
                null}
                {waitingFullscreen ?
                <FullscreenLoading/>:
                null}
                </form>
                
            </div>
            <div className="col-span-1">
                <DisplayPersona
                    persona={persona}
                    setPersona={setPersona}
                />
                <button className="dark:text-white p-2 border dark:border-white rounded-lg bg-green-500" onClick={()=>{
                    setWaitingFullscreen(true)
                    initPersona(persona)
                    .then((a)=>{
                        setWaitingFullscreen(false)
                    })
                }}>Generate new Persona</button>
            </div>
        </div>
    </div>
}

export default Persona