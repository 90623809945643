"use client";
import requestApi from "../lib/request"
import { useState } from "react"
import { useUserContext } from "../context/userContext"
const Debug = ({content})=>{
    const {token} = useUserContext()
    const [response, setResponse] = useState(false)
    const click = (category)=>{
        const resp = requestApi({token, url:'request/', params:{res:'debug', action:category}})
    }
    return <div className="currentContent">
        {/* <TopBar
            products={products}
            showProduct = {setDisplayitem}
            activeElement = {displayItem.shortName}
        /> */}
        <p>{JSON.stringify(content)}</p>
        <div className="mt-2">
            <p className="text-xl">Available Actions</p>
            <div onClick={()=>{
                click('createNews')
            }} className="border border-white rounded p-2 text-white m-2">Request News</div>
            <div onClick={()=>{
                click('createQuiz')
            }} className="border border-white rounded p-2 text-white m-2">Request Quiz</div>
            <div onClick={()=>{
                click('quarksScript')
            }} className="border border-white rounded p-2 text-white m-2">Request Quarks script</div>
        </div>
    </div>
}

export default Debug