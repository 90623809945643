import React from 'react';
import StepperStep from './StepperStep';
import { useStep } from '../context/StepContext';

const StepperHorizontal = () => {
    const { activeStep, setActiveStep } = useStep();
    return (
        <div className="flex flex-row items-center justify-center mx-6 pt-12">
          <StepperStep title="Artikel wählen" active={activeStep >= 1 } icon={<span>1</span>} />
          <div className="border-l border-green-500 h-28 mx-4 mb-6 mt-6">&nbsp;</div>
          <StepperStep title="Bearbeiten" active={activeStep >= 2} icon={<span>2</span>} />
          <div className="border-l border-green-500 h-28 mx-4 mb-6 mt-6">&nbsp;</div>
          <StepperStep title="Übersicht" active={activeStep >= 3} icon={<span>3</span>} />
          <div className="border-l border-green-500 h-28 mx-4 mb-6 mt-6">&nbsp;</div>
          <StepperStep title="Exportieren" active={activeStep >= 4} icon={<span>4</span>} />
        </div>
      );
      
};

export default StepperHorizontal;
