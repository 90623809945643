"use client";
import React, { useState } from "react";
import signup from "../lib/signup";

const Signup = ({toggleSignup}) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordRepeat, setPasswordRepeat] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    // const navigate = useNavigate();

    const onButtonClick = async () => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (password !== passwordRepeat){
            setPasswordError("passwords do not match")
        }else if(!re.test(email)){
            setEmailError("something is wrong with your email")
        }
        else{
            let loginReq = await signup({email, password1:password, password2:passwordRepeat})
            if (loginReq.ok){
                toggleSignup()
            }
        }
    }

    return <div className={"mainContainer text-white"}>
        {/* <div className={"titleContainer"}>
            <div>Login</div>
        </div> */}
        <br />
        <form className="text-white">
            <div className={"inputContainer"}>
                <input
                    value={email}
                    placeholder="Enter email"
                    onChange={ev => setEmail(ev.target.value)}
                    className={"inputBox text-black px-2 py-1 border rounded-lg"} />
                <label className="errorLabel text-black dark:text-white">{emailError}</label>
            </div>
            <br />
            <div className={"inputContainer text-black"}>
                <input
                    type="password"
                    value={password}
                    placeholder="Enter password"
                    onChange={ev => setPassword(ev.target.value)}
                    className={"inputBox px-2 py-1 border rounded-lg" } />
                <label className="errorLabel dark:text-white">{passwordError}</label>
            </div>
            <br />
            <div className={"inputContainer text-black"}>
                <input
                    type="password"
                    value={passwordRepeat}
                    placeholder="Repeat password"
                    onChange={ev => setPasswordRepeat(ev.target.value)}
                    className={"inputBox px-2 py-1 border rounded-lg"} />
                <label className="errorLabel dark:text-white">{passwordError}</label>
            </div>
            <br />
            <div className={"inputContainer border rounded-lg cursor-pointer"} onClick={onButtonClick}>
                <input
                    className={"inputButton dark:text-white text-black px-2 py-1 cursor-pointer"}
                    type="button"
                    value={"Sign up"} />
            </div>
        </form>
        <div onClick={toggleSignup} className="mt-5 border text-black dark:text-white dark:border-white rounded p-2 text-justify cursor-pointer">
            <p>Allready have an account? Login here.</p>
        </div>
    </div>
}

export default Signup