import React, { useState, useEffect} from "react"
import { useChatContext } from "../context/chatContext"
import { useStep } from "../context/StepContext"
import ReactAudioPlayer from "react-audio-player"
import SearchResult from "../ui/SearchResult"
import DialogueOptionModal from '../ui/DialogueOptionModal';
import SearchBar from '../ui/SearchBar';
import Footer from "../ui/Footer"
import AdjustAssistant from "../ui/AdjustAssistant"
import LoadingComponent from "../ui/loading"


const QDuo = ({})=> {
    const { searchArticle, makeArticleAvailable, createAssistant, requestDialogue, requestAnswer, createQuestionAudio, deleteAssistant, deleteFile } = useChatContext()
    const [isQuestionAnsEditted, setIsQuestionAnsEditted] = useState({
        index: null,
        edited: false,
        editedType: null
    });
    const [dialogState, setDialogState] = useState({
        assistantAiId: null,
        id: null,
        isOpen: false
      });
    const [assistantId, setAssistantId] = useState('')
    const [activeThread, setActiveThread] = useState('not set')
    const [searchTerm, setSearchTerm] = useState('')
    const [questions, setQuestions] = useState([])
    const [inDialogue, setInDialogue] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const {activeStep, setActiveStep} = useStep()
    const [initialPrompt, setInitialPrompt] = useState("")
    const [currentDocumentAiId, setCurrentDocumentAiId] = useState('')
    const [loading, setLoading] = useState(false)

    const startDialogue = ({assistantAiId, numOfQuest})=>{
        setLoading(true)
        setAssistantId(assistantAiId)
        requestDialogue(assistantId, numOfQuest)
        .then((data)=>{
            setLoading(false)
            if (!data || data === 'error') return
            console.log('received data: ', data)
            setInitialPrompt(data.assistantPrompt)
            setAssistantId(data.assistantAiId)
            setQuestions(data.questions.map(({question}, index)=>{
                return {question, answer:'', index, editable:false, editableAnswer:false, threadId:data.threadId}
            }))
            setInDialogue(true)
            setActiveThread(data.threadId)
            setActiveStep(activeStep+1)
        })
        // .catch((error) => {
        //     console.error('Error generating dialogue', error);
        // });
    }
    const updateQuestAnswer = (value, editableType, editable, type, index) => {
        setQuestions(questions.map((q, i)=>{
            if(i === index) {
                q[editableType] = editable
                q[type] = value

                if (editable && type === "question") {
                    setIsQuestionAnsEditted({
                        index,
                        edited: true,
                        editedType: type
                    });
                } else if (editable && type === "answer" && q.answerAudio) {
                    setIsQuestionAnsEditted({
                        index,
                        edited: true,
                        editedType: type
                    });
                } 
            }
            return q
        }))
    }
    const generateAnswer = (questionObj) => {
        //Make it null before genrating answer again!
        setQuestions(questions.map((q)=>{
            if (q.index === questionObj.index) q.answerAudio = null
            return q
        }))
        setIsQuestionAnsEditted({
            index:questionObj.index,
            edited: false,
            editedType: null
        });
        const {index, threadId} = questionObj

        editQuestion({index:-1}) //this saves temp quest changes to the questions state before making the API request.
        setLoading(true)
        const question = questions[index].question
        questionObj = {question, threadId, assistantAiId:assistantId, index}
        console.log('questionObj: ', questionObj)
        requestAnswer(questionObj)
        .then((data)=>{
            setLoading(false)
            let answerX = data.questions[0].answer
            const {index} = questionObj
            setQuestions(questions.map(q=>{
                if(q.index === index)q.answer = answerX
                return q
            }))
        }).catch((error) => {
            setLoading(false)
            console.error('Error generating answer', error);
        });

    }

    const regenerateAnswer = ({ index, question }) => {
        generateAnswer({ question, threadId: activeThread, index });
        setIsQuestionAnsEditted({
            index,
            edited: false,
            editedType: null
        });
    }

    const regenerateAudio = ({ index, answer }) => {
        createAudio({ index, answer });
        setIsQuestionAnsEditted({
            index,
            edited: false,
            editedType: null
        });
    };

    const editQuestion = ({index})=>{
        setQuestions(questions.map(q=>{
            if(q.index === index){
                q.editable = true
            }
            return q
        }))
    }
    const search = (term) => {
        searchArticle(term)
        .then((data)=>{
            setSearchResults(data.map((result)=>{
                return  <SearchResult
                key={result.id}
                styleString={`dark:text-white my-2 p-2 border rounded-lg${result.documentAiId ? ' dark:bg-green-500' : '' }`}
                result={result}
                deleteAssistant={({assistantAiId})=>{
                    deleteAssistant({assistantAiId})
                    .then(()=>search(searchTerm))
                }}
                deleteFile={({documentAiId})=>{
                    deleteFile({documentAiId})
                    .then(()=>search(searchTerm))
                }}
                processArticle={(id)=>{
                    makeArticleAvailable(id)
                    .then(()=>{search(searchTerm)});
                }}
                createAssistant={(id)=>{
                    createAssistant(id)
                    .then(()=>search(searchTerm))
                }}
                openDialog={setDialogState}
                setInitialPrompt={setInitialPrompt}
                setCurrentDocumentAiId={setCurrentDocumentAiId}
                setAssistantId={setAssistantId}
                />
            }))
            return
        });
    }     
    useEffect(()=>{
        if (searchTerm.length < 3) {
            setSearchResults([])
            return
        }
        search(searchTerm)
    }, [searchTerm, activeStep])

    const createAudio = ({index})=>{
        //Make it null before generating again!
        setQuestions(questions.map((q)=>{
            if (q.index === index) q.answerAudio = null
            return q
        }))
        setIsQuestionAnsEditted({
            index,
            edited: false,
            editedType: null
        });
        createQuestionAudio({answer:questions[index].answer})
        .then((res)=>{
            setQuestions(questions.map((q)=>{
                if (q.index === index) q.answerAudio = res
                return q
            }))
        });
    }
    return <div className="currentContent mb-20">
        {loading ? <LoadingComponent/> : null}
            {inDialogue && activeStep > 1? 
            <div>
                <div>
                <div>Conversation ID: {activeThread}</div>
                <div>Assistant ID: {assistantId}</div>
                <AdjustAssistant
                    createNewAssistant={createAssistant}
                    setAssistantAiId={setAssistantId}
                    currentDocumentAiId={currentDocumentAiId}
                    initPrompt={initialPrompt}
                    setInitialPrompt={setInitialPrompt}
                />
                {questions.map(({question, index, answer, editable, editableAnswer, answerAudio})=>{
                return <div className="p-2 m-2 dark:text-white border  dark:border-white rounded-lg flex flex-col" key={index}>
                    {editable?
                    <textarea onChange={(e)=>{
                        updateQuestAnswer(e.target.value, "editable", true, "question", index)
                    }} onBlur={(e)=>{
                        updateQuestAnswer(e.target.value, "editable", false, "question", index)
                    }} className="text-black border dark:border-white rounded-lg w-auto mr-5 ml-2 m-10 mb-3 p-10 min-h-14 bg-white" value={question}></textarea>
                    :
                    <div onClick={(e)=>{updateQuestAnswer(question, "editable" , true , "question", index)}} className="questionDiv border dark:border-white rounded-lg list-decimal ml-2 m-10 mb-3 p-10 min-h-14 dark:bg-green-500">{question}</div>
                    }
                    {isQuestionAnsEditted.index === index && isQuestionAnsEditted.edited && isQuestionAnsEditted.editedType === "question" && answer && (
                        <button
                            onClick={() => regenerateAnswer({ index, question })}
                            className="text-white dark:text-white my-2 p-2 border rounded-lg bg-red-500 dark:bg-black m-10 mt-3"
                        >
                            Regenerate Answer
                        </button>
                    )}
                    {answer?
                    <div className={`bottom-4 right-4 flex flex-col justify-between`}>
                        {editableAnswer?
                        <textarea onChange={(e)=>{
                        updateQuestAnswer(e.target.value, "editableAnswer" , true , "answer", index)
                        }} onBlur={(e)=>{
                        updateQuestAnswer(e.target.value, "editableAnswer", false, "answer", index)
                        }} className="text-black border dark:border-white rounded-lg w-auto mr-5 ml-2 m-10 mb-3 p-10 min-h-14 bg-white" value={answer}></textarea>
                        :
                        <div onClick={(e)=>{updateQuestAnswer(answer, "editableAnswer" , true , "answer", index)}} className="border dark:border-white rounded-lg list-decimal ml-2 m-10 mt-3 mb-3 p-10 min-h-14 justify-between dark:bg-green-500">{answer}</div>
                        }
                      
                        {answerAudio?
                        <ReactAudioPlayer
                        src={answerAudio}
                        controls
                        />
                        :  
                        <button onClick={()=>createAudio({index})} className="text-black dark:text-white my-2 p-2 border rounded-lg bg-white dark:bg-black m-10 mt-3">
                            Audio anfragen
                        </button>
                        }
                        {isQuestionAnsEditted.index === index && isQuestionAnsEditted.edited  && isQuestionAnsEditted.editedType === "answer" && answerAudio && (
                            <button
                                onClick={() => regenerateAudio({ index, answer })}
                                className="text-white dark:text-white my-2 p-2 border rounded-lg bg-red-500 dark:bg-black m-10 mt-3"
                            >
                                Audio erneut anfragen
                            </button>
                        )}
                    </div>
                    :
                    <button onClick={ (e)=>{generateAnswer({question, threadId:activeThread, index})}} 
                        className="text-black dark:text-white my-2 p-2 border rounded-lg bg-slate-200 dark:bg-black" 
                        key={index+'btn1'}>
                            Antwort anfragen
                    </button>
                    }
                </div>
                })}
            </div>
        </div>
        :
        <div>
            <SearchBar
                onSearch={setSearchTerm}
                containerCss="mb-4 border rounded dark:bg-white ml-5 p-5 m-5"
                headline="Nach Artikeln suchen::"
                placeholder="Artikel suchen"
                minSearchLength={3}
                type={'text'}
            />
            {searchResults}
        </div>
        }
        <DialogueOptionModal
            dialogueState={dialogState}
            onRequestClose={()=>{setDialogState({
                assistantAiId:null,
                id:null,
                isOpen: false})}}
            handleOptionClick={({numOfQuest}) => {
                startDialogue({ assistantId:assistantId, numOfQuest})
                setDialogState({...dialogState, isOpen:false})
            }}
        />
        <Footer 
            canProceed={activeStep === 1 ? false : true}
        />
    </div>
}

export default QDuo