import Modal from 'react-modal';
import { useState } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DialogueOptionModal = ({ dialogueState, onRequestClose, handleOptionClick }) => {
  let hasCachedData = false
  const [isStartDialogueSelected, setIsStartDialogueSelected] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('5');

  const showToastMessage = (type, duration, message) => {
      if(type === 'error') {
        toast.error(message, {
          autoClose: duration, 
        });
      } else if(type === 'success'){
        toast.success(message, {
          autoClose: duration, 
        });
      }
  }

  const handleOkClick = () => {
    if(textFieldValue === "" || parseFloat(textFieldValue) <= 0){
      showToastMessage('error', 3000, 'Please enter a number. (Required)')
      return
    }
    handleOptionClick({numOfQuest:!isStartDialogueSelected ? textFieldValue : ''})
    onRequestClose();
  };

  const handleKeyPress = (e) => {
    const isNumericInput = (e.key >= "0" && e.key <= "9") || e.key === "Backspace" || e.key === "Delete";
  
    if (!isNumericInput) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={dialogueState.isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Dialogue Options"
      className="fixed inset-0 flex items-center justify-center "
      overlayClassName="fixed inset-0"
    >
      <div className="p-6 rounded-lg w-96" style={{ backgroundColor: '#000' }}>
        {
        hasCachedData ? 
        <>
          <div className="flex justify-between mb-4">
            <Switch
              onChange={() => setIsStartDialogueSelected(!isStartDialogueSelected)}
              checked={isStartDialogueSelected}
              onColor="#007AFF" 
              offColor="#e5e5ea"
              checkedIcon={false}
              uncheckedIcon={false}
            />
            <label className="text-white ml-2">Dialog starten</label>

            <Switch
              onChange={() => setIsStartDialogueSelected(!isStartDialogueSelected)}
              checked={!isStartDialogueSelected}
              onColor="#007AFF"
              offColor="#e5e5ea"
              checkedIcon={false}
              uncheckedIcon={false}
            />
            <label className="text-white ml-2">Previous Dialogue</label>
          </div>
        </> : <>
          <div className="mb-4">
            <label className="text-white block mb-2">Anzahl der Fragen:</label>
            <input
              type="text"
              className="px-4 py-2 border rounded w-full"
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
        </>

        }
        {isStartDialogueSelected && (
          <div className="mb-4">
            <label className="text-white block mb-2">Anzahl der Fragen:</label>
            <input
              type="text"
              className="px-4 py-2 border rounded w-full"
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
              onKeyPress={handleKeyPress}
              disabled={!isStartDialogueSelected}
            />
          </div>
        )}
        <div className="text-center">
          <button
            className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded w-full"
            onClick={handleOkClick}
          >
            Fragen generieren
          </button>
        </div>
        <div className="text-center mt-4">
          <button
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded w-full"
            onClick={onRequestClose}
          >
            schließen
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DialogueOptionModal;
