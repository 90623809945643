import { baseUrl } from "./.urlPath"
// const baseUrl = 'http://localhost:8000/api/'

const requestApi = async ({token, url, params=false, body}) => {
    if (params){
        url +='?' + new URLSearchParams(params)
    }
    let fetchObj = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization':  `Token ${token}`
          },
    }
    if (body){
        console.log("THIS SHOULD BE A POST ", body)
        fetchObj.method = 'POST'
        fetchObj.body = JSON.stringify(body)
    }
    let res = fetch(baseUrl + url, fetchObj)
    .then(r => {
        return r.json()
    })
    .then(r => {
            return r
        })
    .catch(error=>error)
    return await res
}
export default requestApi