import React from 'react';

const FullscreenLoading = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur-3xl backdrop-opacity-80">
      <div className="border-t-4 border-orange-500 rounded-full animate-spin w-12 h-12"></div>
    </div>
  );
};

export default FullscreenLoading;
