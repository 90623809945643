"use client";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import logIn from "../lib/login";
import { useUserContext } from "../context/userContext";

const NavBar = ({items, activeElement, onClick}) => {
    const navItems = items.map(item => {
        let cssClasses = "m-2 cursor-pointer"
        if (item == activeElement){
            cssClasses += ' border rounded bg-green-500 dark:bg-white dark:text-black p-2'
        }
        return <div onClick={()=>onClick(item)} className={cssClasses} key={item}>{item}</div>
    });
    return <div className={"mainContainer dark:text-white items-center flex flex-col flex-grow justify-center"}>
        {navItems}
    </div>
}

export default NavBar