import React from 'react';

const LoadingComponent = () => {
  return (
    <div className="flex flex-row">
      <div>Loading</div>

      <div className="loading-dots flex ml-3 mt-2">
        <div className="dot animate-ping ml-3"></div>
        <div className="dot animate-ping delay-100 ml-3"></div>
        <div className="dot animate-ping delay-200 ml-3"></div>
      </div>
    </div>
  );
};

export default LoadingComponent;
