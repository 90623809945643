"use client";
import React, { useState } from "react";
import { useUserContext } from "../context/userContext";

const TextInput = ({submitButton, inputList, cssAdditions:{containerCss, inputCss, buttonCss}, headline}) => {
    const inputFields = inputList.map(({type, value, placeholder, onChange}, index)=>{
        return <div className={"inputContainer " + containerCss} key={index}>
            <div className="dark:text-black px-2 pt-2">{headline}</div>
            <input
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={ev => onChange(ev.target.value)}
                className={"inputBox text-black px-2 py-1"} />
            {/* <label className="errorLabel text-white">{emailError}</label> */}
        </div>
    })
    return <div className={"mainContainer dark:text-white"}>
        <br />
        <form className="dark:text-white">
            {inputFields}
        </form>
        {submitButton ?
        <div onClick={()=>submitButton.callback} className="mt-5 border border-white rounded p-2 text-justify">
            <p>{submitButton.text}</p>
        </div> :
        null
        }
    </div>
}

export default TextInput