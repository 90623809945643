import { useState } from "react"
const Extendable = ({text, audio}) => {
    const audioPlayer = new Audio(audio)
    const [isPlaying, setIsPlaying] = useState(false)
    
    const click = (e)=>{
        // audioPlayer.play()
        // console.log(e)
        e.target.parentElement.parentElement.classList.remove('text-limiter')
        e.target.parentElement.remove()
        setIsPlaying(true)

    }
    return  <div id="testContainer" className='text-limiter'>
        
  </div>;
  }
  const styles = {
    mini:{

    },
    max:{

    },
  }
  export default Extendable;