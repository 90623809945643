"use client";
const SearchResult = ({result, styleString, processArticle, openDialog, createAssistant, deleteAssistant , deleteFile, setCurrentDocumentAiId, setAssistantId}) => {
    const {title, id, documentAiId, assistantAiId} = result
    return <div className={`${styleString} flex m-5`}>
                <div className="flex flex-col">
                    <div>
                    Article name: <span className="font-bold">{title}</span>
                    </div>
                    {documentAiId && (
                    <div>
                        File ID: <span className="font-bold">{documentAiId}</span>
                    </div>   
                    )}
                    { !assistantAiId && documentAiId && (
                        <div>
                            <button onClick={(e)=>{
                                createAssistant(documentAiId, e)
                                setCurrentDocumentAiId(documentAiId)    
                            }} className="text-white my-2 p-2 border rounded-lg bg-black">
                            Assistenten erstellen=
                            </button>
                            <button onClick={(e)=>{deleteFile({documentAiId, id})}} className="text-white my-2 p-2 border rounded-lg bg-red-500">
                            Datei löschen
                            </button>
                        </div>
                        )}
                    {!documentAiId && (
                    <div onClick={(e)=>{processArticle(id, e)}} className="text-white my-2 p-2 border rounded-lg bg-black dark:bg-green-500 cursor-pointer">
                        Artikel für KI-Nutzung vorbereiten.
                    </div>
                    )}
                    {assistantAiId && (
                    <div>
                        Assistant Id: <span className="font-bold">{assistantAiId}</span>
                    </div>
                    )}
                </div>
                {assistantAiId && (
                    <div className="ml-auto">
                    <button onClick={() => {
                        deleteAssistant({assistantAiId})
                    }} className="text-white my-2 p-2 border rounded-lg bg-red-500">
                        Assistenten löschen
                    </button>
                    <button onClick={(e)=>{deleteFile({documentAiId, id})}} className="text-white my-2 p-2 border rounded-lg bg-red-500">
                        Datei Löschen
                    </button>
                    <button onClick={(e) => {
                        openDialog({ assistantAiId:assistantAiId, id, isOpen:true})
                        setCurrentDocumentAiId(documentAiId)
                        setAssistantId(assistantAiId)
                    }} className="text-white my-2 p-2 border rounded-lg bg-black">
                    Dialog starten
                    </button>
                    </div>
                )}
            </div>
}

export default SearchResult