import requestApi from "../lib/request"
import { useState, useEffect, useRef} from "react"
import { useUserContext } from "../context/userContext"
import { useChatContext } from "../context/chatContext"
import Spinner from "../ui/spinner"
const KAd = ({products, setDisplayitem, activeNavElement, displayItem})=>{
    const {token} = useUserContext()
    const { audioInit, audioScript, createAudioSnippet, createRadioAd} = useChatContext()

    const [waiting, setWaiting] = useState(false)
    const [prompt, setPrompt] = useState('')
    const [temp, setTemp] = useState('')
    const [chatInput1, setChatInput1] = useState('')
    const [chatInput2, setChatInput2] = useState('')
    const [chatInput3, setChatInput3] = useState('')
    const [chatInput4, setChatInput4] = useState('')
    const [textArea, setTextArea] = useState('')
    const [lastResp, setLastResp] = useState('')
    const onClick = (e) => {
        e.preventDefault()
        setWaiting(true)
        const formData = {
            prompt,
            temp,
            content:{
                name:chatInput1,
                date:chatInput2,
                important1:chatInput3,
                important2:chatInput4,
                textArea
            }
        }
        createRadioAd(formData)
        .then(({ads})=>{
            let adsJsx = ads.map(ad=>{
                return <div className="text-yellow" key={ad.adContent}>
                    <div>Seconds approx: {ad.adContent.split(' ').length/180*60}</div>
                    <div>{ad.adContent}</div>
                </div>
            })
            setLastResp(adsJsx)
            setWaiting(false)
        })
    }       
    return <div className="currentContent">
            {waiting ?
            <Spinner/>:
            null}
            <form onSubmit={(e)=> console.log('form submitted')} className="flex dar:text-white flex-col items-center gap-y-2 border dark:border-white p-2 rounded mb-4" name="adContent">
            <label >
                    agentPrompt:
                    <input
                        type="text"
                        value={prompt}
                        placeholder="Enter Name"
                        onChange={ev => setPrompt(ev.target.value)}
                        className={"inputBox px-2 py-1 text-black"} 
                        />
                </label>
            <label >
                    temperature:
                    <input
                        type="text"
                        value={temp}
                        placeholder="Enter Name"
                        onChange={ev => setTemp(ev.target.value)}
                        className={"inputBox px-2 py-1 text-black"} 
                        />
                </label>
            </form>
            <form onSubmit={(e)=> onClick(e)} className="flex dark:text-white flex-col items-center gap-y-2 border dark:border-white p-2 rounded" name="adContent">
                <label >
                    Name:
                    <input
                        type="text"
                        value={chatInput1}
                        placeholder="Enter Name"
                        onChange={ev => setChatInput1(ev.target.value)}
                        className={"inputBox px-2 py-1 text-black "} 
                        />
                </label>
                <label>
                    Datum:
                    <input
                        type="text"
                        value={chatInput2}
                        placeholder="Enter Message"
                        onChange={ev => setChatInput2(ev.target.value)}
                        className={"inputBox px-2 py-1 text-black "} 
                        />
                </label>
                <label>
                    WIP:
                    <input
                        type="text"
                        value={chatInput3}
                        placeholder="Enter Message"
                        onChange={ev => setChatInput3(ev.target.value)}
                        className={"inputBox px-2 py-1 text-black"} 
                        />
                </label>
                <label>
                    WIP2:
                    <input
                        type="text"
                        value={chatInput4}
                        placeholder="Enter Message"
                        onChange={ev => setChatInput4(ev.target.value)}
                        className={"inputBox px-2 py-1 text-black"} 
                        />
                </label>
                <label>
                    Mehr Information:
                    <textarea
                        cols={40}
                        rows={10}
                        value={textArea}
                        onChange={ev => setTextArea(ev.target.value)}
                        className="text-black border-2"
                    />
                </label>
                <button className="dark:text-white p-2 border dark:border-white rounded-lg mx-2" type="submit">Generate Ad text</button>
            {waiting ?
            <Spinner/>:
            null}
            </form>
            {lastResp ? 
                <div>
                    {lastResp}
                    <div className="text-white">WHHHHHHHHHY IS THIS NOT SHOWING UP?</div>
                </div> :
                null
            }
    </div>
}

export default KAd