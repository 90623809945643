"use client";
import { useState } from "react"
import Login from "../ui/login"
import Signup from "../ui/signup"
const LoginPage = ()=>{
    const [showLogin, setShowLogin] = useState(true)
    const toggle = ()=>{
        setShowLogin(!showLogin)
    }
    return <div className="grid grid-cols-6 min-h-screen content-center">

        <div className={'col-span-4 content-center justify-center flex my-auto'}>
            <p>Konsole Content</p>
        </div>
        {showLogin ? 
        <Login
            toggleSignup = {toggle}
        /> :
        <Signup
            toggleSignup = {toggle}
        />}
    </div> 
}

export default LoginPage