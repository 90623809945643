//const baseUrl = 'http://localhost:8000/api/auth/register/'
import { registerUrl } from "./.urlPath"
const signup = async ({email, password1, password2}) => {
    let resp = fetch(registerUrl, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
          },
        body: JSON.stringify({email, password1,password2})
    })
    .then(r => r)
    // .then(r => {
    // })
    return await resp
}
export default signup