import React from 'react';
import logo from '../img/k_logo.jpeg';
import CustomButton from './CustomButton';
import { useStep } from '../context/StepContext';

const Footer = ({adText, canProceed, buttonRight, buttonLeft, maxSteps = 4}) => {
    const { activeStep, setActiveStep } = useStep();
    
  return (
    <div className="text-black bottom-0 fixed w-full border border-l-0 grid grid-cols-5">
        {/* <div className="flex items-center  bg-gray-200 p-5 col-span-1"> 
          <div className='ml-20'>
            <p className="text-xs">Developed By</p>
            <p className="text-sm font-bold">Konsole Labs</p>
          </div>
          <img src={logo} alt="Company Logo" className="w-8 ml-2" />
        </div> */}
        <div className='col-span-5 flex bg-white'>
          <div className='flex flex-grow items-center justify-center'> 
            <CustomButton title={"Back"} 
                  callback={()=>{setActiveStep(activeStep > 1 ? activeStep - 1 : activeStep)}}/>
          </div>
          <div className='flex flex-grow items-center justify-center'> 
          {activeStep<4 ?
          <div className='flex'>
            {buttonRight && buttonRight}
            <CustomButton
                disabled={canProceed === false ? true : false}
                callback={()=>{
                  if (activeStep< maxSteps){
                    setActiveStep(activeStep + 1)
                    return
                  }
                }}
                title={"Next step"}/>
          </div>
              :
              null
          }
          </div>
        </div>
    </div>
  );
};

export default Footer;
