import requestApi from "../lib/request"
import { useState, useEffect } from "react"
import { useUserContext } from "../context/userContext"
import { useChatContext } from "../context/chatContext"
import Spinner from "../ui/spinner"
import Extendable from "../ui/extendable"
const DanexisTrends = ({props})=>{
    const {token} = useUserContext()
    const { getTrendsAndCampaigns, getNewRadioAd} = useChatContext()
    const [trends, setTrends] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [waiting, setWaiting] = useState(false)
    const [response, setResponse] = useState({})
    const [selectedCampaign, setSelectedCampaign] = useState('')
    const [selectedTrend, setSelectedTrend] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [showData, setShowData] = useState(true)
    const [generatedArticle, setGeneratedArticle] = useState(false)

    useEffect(() => {
        if (selectedCampaign && selectedTrend){
            setModalVisible(true)
        }
    }, [selectedCampaign, selectedTrend])
    const selectTrend = (e, trend)=>{
        document.querySelector('.selectedTrend')?.classList.remove('bg-green-500', 'text-white', 'selectedTrend')
        e.currentTarget.classList.add('bg-green-500', 'text-white', 'selectedTrend')
        setSelectedTrend(trend)
    }
    const selectCampaign = (e, campaign)=>{
        document.querySelector('.selectedCampaign')?.classList.remove('bg-green-500', 'text-white', 'selectedCampaign')
        e.currentTarget.classList.add('bg-green-500', 'text-white', 'selectedCampaign')
        setSelectedCampaign(campaign)
    }
    const generateArticle =()=>{
        setModalVisible(false)
        setShowData(false)
        setWaiting(true)
        getNewRadioAd(selectedTrend, selectedCampaign.id)
        .then(({title, paragraphs})=>{
            const newArticle = <Extendable
                text={<div>
                    <div className="font-black text-base my-2">{title}</div>
                    {paragraphs.map((paragraph)=>{
                        return <div className="mb-1">{paragraph}</div>
                    })}
                </div>}
                audio={selectedCampaign.audio}
            />
            setGeneratedArticle({title, paragraphs})
            setWaiting(false)
        })
    }
    const loadData = ()=>{
        const placeHolderNames = ['Morgen Show', 'Mint Freeze Gum', 'Mint Freeze Gum']
        getTrendsAndCampaigns()
        .then((res)=>{
            setCampaigns(res.campaigns.map((campaign, index)=>{
                return <div key={campaign.id} className="border rounded-lg p-2 m-2" onClick={(e)=>{selectCampaign(e, campaign)}}>
                    {campaign.name}
                    <br/>
                    Product: {placeHolderNames[index]}
                    <br/>
                    Status: {campaign.status}
                    <br/>
                    Current Impressions: {campaign.currentImpressions}
                    <br/>
                    Target Impressions: {campaign.targetImpressions}
                    <br/>
                    Progress: {parseInt(campaign.currentImpressions/campaign.targetImpressions*100)}%
                </div>
            }))
            setTrends(res.trends.map((trend, index)=>{
                return <div key={index} className="border rounded-lg p-2 m-2" onClick={(e)=>{selectTrend(e, trend)}}>
                    {trend.split('\n').map((paragraph, index)=>{
                        return <div key={index}>{paragraph}</div>
                    })}
                </div>
            }))
        })
    }
    return  <div className="flex flex-col sampleContainer">
            <button onClick={loadData} className="bg-black text-white border rounded-lg p-2 m-2">Load Live data</button>
            {showData ?
            <div className="grid grid-cols-6 content-center">
                <div className={'col-span-3 content-center  flex flex-col'}>
                    <p>Available campaigns:</p>
                    {campaigns}
                </div>
                <div className={'col-span-3 content-center flex flex-col'}>
                    <p>Current Trends:</p>
                    {trends}
                </div>
            </div> :
            null }
            {modalVisible ? 
            <button className="fixed w-60 h-10 border-2 rounded-xl border-white p-2 bg-green-500 m-auto left-0 bottom-0 right-0 text-center " onClick={generateArticle}>create article</button> :
            null}
            {waiting?
            <Spinner/> : 
            null }
            {generatedArticle ?
            <Extendable
            text={<div>
                <div className="font-black text-base my-2">{generatedArticle.title}</div>
                {generatedArticle.paragraphs.map((paragraph, index)=>{
                    return <div className="mb-1" key={index}>{paragraph}</div>
                })}
                </div>}
                audio={selectedCampaign.audio}
            /> : 
            null }
        </div>
}
export default DanexisTrends