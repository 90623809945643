"use client";
import TextInput from "./textInput";
import { useChatContext } from "../context/chatContext";
import { useState, useEffect } from "react";

const SearchCore = ({setResults, results, resultCB}) => {
    const { searchArticle } = useChatContext()
    const [searchTerm, setSearchTerm] = useState('')
    useEffect(()=>{
        if (searchTerm.length < 3) {
            setResults([])
            return
        }
        searchArticle(searchTerm)
        .then((res)=>{
            setResults(res)
        })
    }, [searchTerm])
    return <div>
                <TextInput
                cssAdditions={{containerCss:'mb-4 border rounded dark:bg-white ml-5'}}
                onButtonClick={''}
                headline={'Enter search term below:'}
                inputList={[{
                    type:'text', 
                    value:searchTerm, 
                    placeholder:'Search for article', 
                    onChange:setSearchTerm
                }]}
            />
            <div className="mx-5 border rounded-l p-2">
                <span className="font-bold">Results:</span>
            {results? results.map((r)=>{
                return <div onClick={()=>{
                    resultCB(r)
                }} key={r.id}>{r.title}</div>
            }):null}
            </div>
    </div>
}

export default SearchCore