'use client';
import React, { useState, useEffect } from "react"
import { useUserContext } from "../context/userContext"
import { useChatContext } from "../context/chatContext";
import requestApi from "../lib/request"
import NavBar from "../ui/nav"
import Stepper from "../ui/Stepper";
import Explore from "../subPages/explore"
import Debug from "../subPages/debug"
import QAssist from "../subPages/qAssist"
import QAudio from "../subPages/qAudio";
import QAudioV2 from "../subPages/qAudio2";
import QAudioV3 from "../subPages/qAudio3";
import KAd from "../subPages/kAd";
import DanexisDemo from "../subPages/danexisDemo";
import DanexisTrends from "../subPages/danexisTrends";
import QDuo from "../subPages/qDuo";
import Persona from "../subPages/persona";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import StepperHorizontal from "../ui/StepperHorizontal";

const Mainpage = (pathname)=>{
    let navigate = useNavigate()
    const {token, logout} = useUserContext()
    const {chatInit} = useChatContext()
    const [products, setProducts] = useState([])
    const [displayItem, setDisplayitem] = useState(false)
    const [activeNavElement, setActiveNavElement] = useState('Explore')
    const location = useLocation();

    const routeMapping = {};

    const componentsMap = {
        QDuo,
        QAudioV3,
        QAudioV2,
        QAssist,
        QAudio,
        KAd,
        DanexisTrends,
        Debug,
        Persona
      };

    products.forEach(product => {
        routeMapping[product.shortName] = `/explore/${product.shortName.replace(/\s+/g, '')}`;
    });

    //TODO WALEED MAYBE FOR THE FUTURE
    // const defaultRoute = '/';
    // const routeValue = routeMapping[displayItem.shortName];

    const navBarClick = (item) => {
        setActiveNavElement(item)
        navigate(`/${item.toLowerCase()}/`);
    }

    useEffect(() => {
        requestApi({token, url:'requestNew/products/'})
        .then(resp=>{
            if (resp.detail == 'Invalid token.') return logout() 
            if(typeof resp === "object") setProducts(resp)
        })
    }, [])

    useEffect(() => {

        const currentPath = location.pathname;
        const product = products.find(item => currentPath === routeMapping[item.shortName]);
      
        if (product) {
            navigate("/explore/" + product.shortName.replaceAll(' ', ''));
            setDisplayitem(product);
        }
      }, [location.pathname, products]);
    return <div className="grid grid-cols-6 min-h-screen">
        <div className={'flex flex-col col-span-1 justify-between'}>
            <NavBar
                items = {['My Account', 'Explore', 'Debug']}
                activeElement = {activeNavElement}
                onClick = {navBarClick}
            />
            <button onClick={logout} className={'bg-green-500'}>Logout</button>
        </div>
        <div className={'col-span-5 '}>

            <>
            <p className="m-5 text-2xl">Konsole Content <span className="text-green-500">{activeNavElement}</span></p>
            
            <Explore
                products = {products} 
                setDisplayitem ={setDisplayitem}
                activeNavElement = {activeNavElement}
                displayItem = {displayItem}
                onClick={chatInit}
            />
                        {displayItem.name === 'Quarks Dialogue'?
            <>
            <StepperHorizontal/>
            </>:
            null}
            {/* TODO WALEED ADD ROUTES FOR TOPBAR // EXPLORE */}
            {
            <Routes>
                <Route path="/" element={null}></Route>
                {Object.keys(routeMapping).map((productName) => {
                const componentName = productName.replace(/\s+/g, '');
                const DynamicComponent = componentsMap[componentName];
                return DynamicComponent ? (
                    <Route
                    key={productName}
                    path={routeMapping[productName]}
                    element={<DynamicComponent />}
                    />
                ) : null;
                })}
            </Routes>
            }
            </>
        </div>
    </div> 
}

export default Mainpage