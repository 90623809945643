import React, { useState } from 'react';
import CustomButton from './CustomButton';
const EditableTextArea = ({content=`defaultContent`, setContent, title='defaultTitle', defaultEdit=false ,placeholder=false, blur=false}) => {
    const [edit, setEdit] = useState(defaultEdit)

    return  <div>
        <div className='text-lg font-bold'>{title}</div>
        {edit ? 
        <>
            <textarea 
            placeholder='Platz für extra Anweisung'
            className="text-black border dark:border-white rounded-lg w-full min-h-32 p-2" 
            value={content}
            onChange={(e)=>{
                setContent(e.target.value)
            }} 
            onBlur={(e)=>{
                if (blur){
                    setEdit(false)
                }
            }} 
            >
            </textarea>
        </> : 
        <div className='border rounded-lg p-2'>{content}</div>
        }
        <CustomButton
            title={edit ? "Speichern":"Editieren"}
            callback={()=>{setEdit(!edit)}}
            background={"bg-green-500 w-72"}
        />
    </div>
  ;
};

export default EditableTextArea;
