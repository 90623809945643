import React, { useState } from 'react';
import CustomButton from './CustomButton';
import EditableTextArea from './EditableTextArea';
const AdjustAssistant = ({currentDocumentAiId, createNewAssistant, setAssistantAiId, initPrompt, setInitialPrompt}) => {
    const [baseInstructions, setBaseInstructions] = useState(initPrompt)
    const [additionalInstructions, setAdditionalInstructions] = useState(``)
    const [open, setOpen] = useState(false)
    return  <div>
        {open ?
        <>
            <EditableTextArea
                title='AI Assistenten Anweisungen:'
                content={baseInstructions}
                setContent={setBaseInstructions}
            />
            {/* <EditableTextArea
                title='Zusätzliche Anweisungen für das beantworten der Fragen:'
                placeholder="Platz für weitere Anweisungen"
                defaultEdit={true}
                content={additionalInstructions}
                setContent={setAdditionalInstructions}
            /> */}
            <CustomButton
                title={"Neuen Dialogassistenten erstellen"}
                callback={()=>{
                    console.log("articleId", currentDocumentAiId)
                    console.log("assistantPrompt", baseInstructions)
                    console.log("additionalPrompt", additionalInstructions)
                    createNewAssistant(currentDocumentAiId, 'dialogue', {assistantPrompt:baseInstructions, additionalPrompt:additionalInstructions})
                    .then(data=>{
                        console.log("Response to edit ai assistant", data)
                        setAssistantAiId(data.assistantId)
                        setInitialPrompt(data.initPrompt)

                    })
                }}
                background={"bg-green-500 w-72"}
            />
        </>:
        null}
        <CustomButton
            title={open ? "Assistentendetails schließen" : "Dialogassistenten anpassen"}
            callback={()=>setOpen(!open)}
            background={open ? 'bg-red-500 w-72' : "bg-green-500 w-72"}
        />
    </div>
  ;
};

export default AdjustAssistant;
