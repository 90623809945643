import { loginUrl } from "./.urlPath.js"
const logIn = async ({email, password}) => {
    console.log("LoginUrl -> " + loginUrl);
    let resp = fetch(loginUrl, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
          },
        body: JSON.stringify({email, password})
    })
    .then(r => r.json())
    .then(r => {
            if (!r.key){
                console.log('Incorrect credentials')
                return false
            } else {
                localStorage.setItem("user", JSON.stringify({email, token: r.key}))
                return {key: r.key}
            }
        })
    return await resp
}
export default logIn