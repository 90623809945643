import React from 'react';
import { useState, useContext, useEffect } from 'react';
import logoutRequest from '../lib/logout';
const user = {
    loggedIn : false,
    token : false,
    data : {name:'DEFAULT NAME'}
}
const GenericContext = React.createContext('');
const useUserContext = ()=> {
    return useContext(GenericContext);
}
const UserContext = ({ children }) => {
    const [loginState, setLoginState] = useState(false)
    const [token, setToken] = useState(false)
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (!user) return
        const {token} = user
        if (token) {
            setToken(token)
            setLoginState(true)
        }
    }, [])
    const logout = ()=>{
        logoutRequest(token)
        setLoginState(false)
        setToken(false)
        if (typeof window !== 'undefined') {
            localStorage.clear()
          }
    }

    return (
        <GenericContext.Provider value={{
            loginState, 
            setLoginState,
            setToken,
            token,
            logout
        }}>
            {children}
        </GenericContext.Provider>
    );
};
export {UserContext, useUserContext};