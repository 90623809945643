import { useState, useEffect} from "react"
import { useChatContext } from "../context/chatContext"
import SearchCore from "../ui/SearchCore"
import Spinner from "../ui/spinner"
const QAssist = ({})=>{
    const {thread, assistant, addMessage, response} = useChatContext()

    const [waiting, setWaiting] = useState(false)
    const [results, setResults] = useState(false)
    const [chatInput, setChatInput] = useState('')
    const [chatHistory, setChatHistory] = useState([
        {role:'ai', content:'Stellen sie mir fragen, ich werde sie basierend auf Quarks wissen beantworten'},
    ])
    useEffect(() => {
        scrollToBottom()
    }, [])
    useEffect(() => {
      scrollToBottom()
    }, [chatHistory]);

    useEffect(() => {
        const aiResponse = {role:'ai', content:response}
        if (!response) {
            setWaiting(false)
            return
        }
        setChatHistory([...chatHistory, aiResponse])
        scrollToBottom()
        setChatInput('')
        setWaiting(false)
    }, [response]);
    
    const submitMessage = (e, msg)=>{
        e.preventDefault()
        if (waiting) return
        const userInput = {role:'user', content:msg}
        setChatHistory([...chatHistory, userInput])
        setWaiting(true)
        addMessage(msg)
    }
    
    const messages = chatHistory.map((message, index)=>{
        const userMsgStyle = 'text-right dark:text-white text-l p-4 mt-2 border dark:border-white rounded-lg place-self-end'
        const partnerMsgStyle = 'text-left text-black text-l p-4 mt-2 bg-white border border-black rounded-lg place-self-start'
        return <div className={`${message.role === 'user' ? userMsgStyle : partnerMsgStyle}`} key={`${message.content}-${index}`}>{message.content}</div>
    })

    const scrollToBottom = () => {
        document.querySelector('.chatHistory')?.scrollTo({
            top: document.querySelector('.chatHistory').scrollHeight,
            behavior: 'smooth',
        });
    }
    return <div className="currentContent">
        <div className="m-5">
            <SearchCore
                setResults={setResults}
                results={results}
            />
            {
                assistant ?
                <div className={"inputContainer text-black w-1/"}>
                    {/* <p className="mb-2 dark:text-white">Current assistant: {assistant.name}</p> */}
                    <p className="mb-2 dark:text-white">Current thread: {thread.id}</p>
                    <div className="chatHistory border border-white rounded-lg p-5 mb-2 min-h-20 max-h-80 overflow-scroll flex flex-col">
                        {messages}
                    </div>
                    <form onSubmit={(e)=> submitMessage(e, chatInput)} className="flex">
                        <input
                            type="text"
                            value={chatInput}
                            placeholder="Enter Message"
                            onChange={ev => setChatInput(ev.target.value)}
                            className={"inputBox px-2 py-1"} 
                            />
                        <button className="dark:text-white p-2 border dark:border-white rounded-lg mx-2" type="submit">Send</button>
                    {waiting ?
                    <Spinner/>:
                    null}
                    </form>
                </div>
                :
                <p>Loading data</p>
            }
        </div>
    </div>
}

export default QAssist