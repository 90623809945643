import requestApi from "../lib/request"
import { useState, useEffect, useRef} from "react"
import { useUserContext } from "../context/userContext"
import { useChatContext } from "../context/chatContext"
import Spinner from "../ui/spinner"
const QAudio = ({products, setDisplayitem, activeNavElement, displayItem})=>{
    const {token} = useUserContext()
    const { audioInit, audioScript, createAudioSnippet, audioSnippet} = useChatContext()

    const [waiting, setWaiting] = useState(false)
    const [chatInput, setChatInput] = useState('')
    const [scriptBpJsx, setScriptBpJsx] = useState(null)
    const submitMessage = (e, msg)=>{
        e.preventDefault()
        if (waiting) return
    }
    const createDetailScript = ({headline, content, index}) => {
        createAudioSnippet({headline, content})
        .then(res=>{
            document.querySelector(`.scriptSection${index}`).insertAdjacentHTML('beforeend', `
                <div>${res}</div>
            `)
        })
    }
    useEffect(()=>{
        if (!audioScript) return
        const sections = audioScript.Podcast.Sections.map((section, index)=>{
            return <div className={`scriptSection${index}`}>
                <p className="text-lg border-b dark:border-white my-4">{section.Headline}</p>
                <p>{section.Details}</p>
                <button className="text-black p-2 border-2 dark:text-white dark:border-white rounded-lg mt-2" onClick={()=>{
                    createDetailScript({headline:section.Headline, content:section.Details, index})
                }}>Create audio script for the above passage </button>
            </div>
        })
        const bpJsx = <div className="m-4 border border-white rounded-lg p-4 ">
            <p className="text-xl text-justify p-2">{audioScript?.Podcast?.Title}</p>
            {sections}
        </div>
        setScriptBpJsx(bpJsx)
        setWaiting(false)
    }, [audioScript])
    const onClick = () => {
        setWaiting(true)
        audioInit()
    }       
    return <div className="currentContent">
            {waiting ?
            <Spinner/>:
            null}
            <div className="flex">
                <button onClick={onClick} className="dark:text-white p-2 border-2 dark:border-white rounded-lg">Load previous script</button>
            </div>
            {audioScript?.Podcast?.Title ?
            <div>{scriptBpJsx}</div> :
            null}
    </div>
}

export default QAudio