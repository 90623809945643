import React, { createContext, useContext, useState, useEffect } from 'react';

import { useUserContext } from './userContext';
import requestApi from '../lib/request';

const RouteContext = createContext();

export const RouteProvider = ({ children }) => {

  const {token} = useUserContext()

  const [routeData, setRouteData] = useState(() => {
    const storedData = localStorage.getItem('routeData');
    return storedData ? JSON.parse(storedData) : {};
  });

  useEffect(() => {
    localStorage.setItem('routeData', JSON.stringify(routeData));
    //uploadDialogue(routeData)
  }, [routeData]);

  const saveRouteData = (route, data) => {
    setRouteData((prevData) => ({
      ...prevData,
      [route]: data,
    }));
  };

  const uploadDialogue = (data) => {
    const resp = requestApi({token, url:'request/', params:{res:'debug', action:"uploadDialogue"}}, data)
    .then((r) => {
      //TODO remove it when its implemented
      console.log("upload data done", r)
      return r
    })
  }

  const clearRouteData = (route) => {
    setRouteData((prevData) => {
      const { [route]: removedRoute, ...remainingData } = prevData;
      return remainingData;
    });
  };

  const getRouteData = (route) => {
    return routeData[route];
  };

  return (
    <RouteContext.Provider value={{ saveRouteData, clearRouteData, getRouteData }}>
      {children}
    </RouteContext.Provider>
  );
};

export const useRouteContext = () => {
  return useContext(RouteContext);
};
