 //const baseUrl = 'http://localhost:8000/api/auth/logout/'
import { logoutUrl } from "./.urlPath.js"
const logout = async (token) => {
    let resp = fetch(logoutUrl, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':  `Token ${token}`
          },
    })
    .then(r => r.json())
    .then(r => {
            return r
        })
    return await resp
}
export default logout
