import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Landingpage from './landingpage/page'
import { UserContext } from './context/userContext';
import { ChatContext } from './context/chatContext';
import { RouteProvider } from './context/RouteProvider';
import { StepProvider } from './context/StepContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserContext>
      <BrowserRouter>
        <RouteProvider>
          <ChatContext>
            <StepProvider> {/* TODOJAN place this context properly */}
              <Landingpage/>
            </StepProvider>
          </ChatContext>
        </RouteProvider>
      </BrowserRouter>
    </UserContext>
    <ToastContainer />
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
