import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SearchBar = ({ onSearch, containerCss, placeholder, headline, minSearchLength = 3, type = 'text'}) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (searchTerm.length >= minSearchLength) {
      onSearch(searchTerm);
    }
  }, [searchTerm, onSearch]);

  return (
    <div className={containerCss}>
      <p className="mb-2">{headline}</p>
      <input
        type={type}
        value={searchTerm}
        placeholder={placeholder}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="px-4 py-2 rounded w-full"
      />
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  containerCss: PropTypes.string,
  placeholder: PropTypes.string,
  headline: PropTypes.string,
};

export default SearchBar;
