// TopBar.js
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const TopBar = ({ products, showProduct, activeElement, loadChat }) => {
  const navigate = useNavigate();
  const selectProduct = (item) => {
    if (item.shortName === 'Q Assist') {
      loadChat();
    }
    navigate("/explore/" + item.shortName.replaceAll(' ', ''));
    showProduct(item);
  };

  const productsJSX = products.map(item => {
    let cssClasses = "m-2 border dark:border-white rounded p-2";
    if (activeElement == item.shortName) cssClasses = "m-2 border border-green-500 text-green-500 rounded p-2";
    
    return (
      <button
        onClick={() => selectProduct(item)}
        className={cssClasses}
        key={item.shortName}
      >
        {item.shortName}
      </button>
    );
  });

  return (
    <div className={"mainContainer dark:text-white items-center flex ml-3"}>
      {productsJSX}
    </div>
  );
};

export default TopBar;
