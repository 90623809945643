import TopBar from "../ui/topBar"
const Explore = ({products, setDisplayitem, displayItem, onClick})=>{
    return <div className="currentContent">
        <TopBar
            products= {products}
            showProduct = {setDisplayitem}
            activeElement = {displayItem.shortName}
            loadChat = {onClick}
        />
        {displayItem?
        <div className="m-5">
            <p className="mb-2">{displayItem.name}</p>
            <p className="border-b border-white pb-2">{displayItem.description}</p>
        </div>:
        null
        }
    </div>
}

export default Explore