import requestApi from "../lib/request"
import { useState, useEffect, useRef} from "react"
import { useUserContext } from "../context/userContext"
import { useChatContext } from "../context/chatContext"
import Spinner from "../ui/spinner"
const QAudioV2 = ({products, setDisplayitem, activeNavElement, displayItem})=>{
    const {token} = useUserContext()
    const { audioInit, audioScript, createAudioSnippet, createAudioScript} = useChatContext()

    const [waiting, setWaiting] = useState(false)
    const [scriptInput, setScriptInput] = useState('')
    const [script, setScript] = useState('')
    const [scriptBpJsx, setScriptBpJsx] = useState(null)
    const submitMessage = (e, msg)=>{
        e.preventDefault()
        if (waiting) return
    }
    const createDetailScript = () => {
        createAudioScript()
        .then(res=>{
            setScriptInput(res)
            setWaiting(false)
        })
    }

    const onClick = () => {
        setWaiting(true)
        createDetailScript()
    }       

    return <div className="currentContent">
            {waiting ?
            <Spinner/>:
            null}
            <div className="flex">
                <button onClick={onClick} className="dark:text-white p-2 border dark:border-white rounded-lg">Load default script</button>
                <button className="dark:text-white p-2 border dark:border-white rounded-lg ml-4">Create script</button>
                <button className="dark:text-white p-2 border dark:border-white rounded-lg ml-4">Send script</button>
            </div>
            <div className="textInput flex-col flex mt-4">
                {/* <input type="text"></input> */}
                <textarea name="postContent" 
                    className='text-black'
                    rows={40} 
                    cols={80} 
                    value={scriptInput} // ...force the input's value to match the state variable...
                    onChange={e => setScriptInput(e.target.value)}
                    />
            </div>
    </div>
}

export default QAudioV2